import { R4 } from '@ahryman40k/ts-fhir-types'
import _ from 'lodash'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodingOfSystemFromCodableConceptList,
  getValueRefValueExtensionsOfUrlForBasic,
} from 'utils/fhirResourcesHelper'

export function getNotesFromAllergy(notes: R4.IAnnotation[] | undefined) {
  let res: string[] | undefined
  if (notes && notes.length > 0) {
    res = []
    notes.forEach((e) => {
      if (e.text) res?.push(e.text.trim())
    })
  }
  return res
}

export function getNotesString(notes: R4.IAnnotation[] | undefined) {
  let res: string[] | undefined
  if (notes && notes.length > 0) {
    res = []
    notes.forEach((e) => {
      if (e.text) res?.push(e.text)
    })
  }
  if (res) {
    return res.toString().trim()
  }
  return ''
}
export function getClinicalStatus(status?: R4.ICodeableConcept) {
  if (status) {
    if (status.coding) {
      const codingData = status.coding ?? []

      if (codingData.length > 0) {
        if (codingData[0].code === 'active') {
          return true
        }
        if (codingData[0].code === 'inactive') {
          return false
        }
      }
    }
  }
  return false
}

export function hasSpaces(str: string) {
  if (str.indexOf(' ') !== -1) {
    return true
  }
  return false
}

export function hasSpacesForNotes(str: string) {
  if (str.indexOf(' ') !== -1) {
    return true
  }
  return false
}

export function hasNotes(notes: R4.IAnnotation[] | undefined) {
  if (notes && notes.length > 0) {
    if (notes[0].text && notes[0].text.length > 0) {
      return true
    }
  }

  return false
}

export function hasAddedBySameDoctor(condition: R4.ICondition) {
  const practRole = getCurrentUserPractitionerRoleDetails()
  if (condition.recorder) {
    const recorderRef = condition.recorder.reference ?? ''
    if (recorderRef.length > 0) {
      const recorderRoleId = recorderRef.split('/')[1]
      if (recorderRoleId === practRole.id!) {
        return true
      }
    }
  }

  return false
}

export function hasAddedBySameDoctorForMedication(
  condition: R4.IMedicationStatement,
  encounterId: string
) {
  let match: boolean = false
  const practRole = getCurrentUserPractitionerRoleDetails()
  if (condition) {
    const id = getValueRefValueExtensionsOfUrlForBasic(
      condition.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-med-recorder-ext'
    )

    if (id.length > 0) {
      if (id === practRole.id!) {
        const ref = condition.context
        if (ref) {
          const refId = ref.reference ? ref.reference.split('/')[1] : ''
          if (refId.length > 0) {
            if (refId === encounterId) {
              match = true
            }
          }
        }
      }
    }
  }

  return match
}

export function hasAddedObsBySameDoc(condition: R4.IObservation) {
  const practRole = getCurrentUserPractitionerRoleDetails()
  if (condition.performer) {
    const performerData = condition.performer ?? []
    for (let i = 0; i < performerData.length; i++) {
      const recorderRef = performerData[i].reference ?? ''
      if (recorderRef.length > 0) {
        const recorderRoleId = recorderRef.split('/')[1]
        if (recorderRoleId === practRole.id!) {
          return true
        }
      }
    }
  }

  return false
}

export function hasAddedBySameDoctorForAllergy(
  condition: R4.IAllergyIntolerance
) {
  const practRole = getCurrentUserPractitionerRoleDetails()
  if (condition.recorder) {
    const recorderRef = condition.recorder.reference ?? ''
    if (recorderRef.length > 0) {
      const recorderRoleId = recorderRef.split('/')[1]
      if (recorderRoleId === practRole.id!) {
        return true
      }
    }
  }

  return false
}

export function hasAddedBySameDoctorProcedure(condition: R4.IProcedure) {
  const practRole = getCurrentUserPractitionerRoleDetails()
  if (condition.recorder) {
    const recorderRef = condition.recorder.reference ?? ''
    if (recorderRef.length > 0) {
      const recorderRoleId = recorderRef.split('/')[1]
      if (recorderRoleId === practRole.id!) {
        return true
      }
    }
  }

  return false
}

export function hasNotesForChief(notes: R4.IAnnotation[] | undefined) {
  if (notes && notes.length > 0) {
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].authorReference && notes[i].authorReference?.reference) {
        if (
          notes[i].authorReference?.reference?.includes('PractitionerRole') ||
          notes[i].authorReference?.reference?.includes('Practitioner')
        )
          return true
      }
    }
  }

  return false
}

export function hasNotesForChiefForNote(notes: R4.IAnnotation[] | undefined) {
  if (notes && notes.length > 0) {
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].authorReference && notes[i].authorReference?.reference) {
        if (
          notes[i].authorReference?.reference?.includes('PractitionerRole') ||
          notes[i].authorReference?.reference?.includes('Practitioner')
        )
          return notes[i]
      }
    }
  }

  return undefined
}

export function hasNotesForChiefForPatient(
  notes: R4.IAnnotation[] | undefined
) {
  if (notes && notes.length > 0) {
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].authorReference && notes[i].authorReference?.reference) {
        if (notes[i].authorReference?.reference?.includes('Patient')) {
          return notes[i]
        }
      }
    }
  }

  return undefined
}

export function hasNotesForChiefForPatientText(
  notes: R4.IAnnotation[] | undefined
) {
  let notesData: string = ''
  if (notes && notes.length > 0) {
    for (let i = 0; i < notes.length; i++) {
      if (notes[i].authorReference && notes[i].authorReference?.reference) {
        if (notes[i].authorReference?.reference?.includes('Patient')) {
          notesData = notes[i].text ?? ''
        }
      }
    }
  }

  return notesData.trim()
}

export function getNotesForPatient(notes: R4.IAnnotation | undefined) {
  if (notes !== undefined) {
    return notes.text ?? ''
  }

  return ''
}

export function getBodySiteName(obs: R4.IObservation) {
  if (obs.bodySite) {
    const codin = getDefaultCodingOfSystemFromCodableConceptList([obs.bodySite])

    if (codin) {
      return _.capitalize(codin.display ?? '')
    }
  }

  return ''
}

export function getPainInText(obs: R4.IObservation) {
  if (obs.interpretation) {
    const codin = getDefaultCodingOfSystemFromCodableConceptList(
      obs.interpretation
    )

    if (codin) {
      return _.capitalize(codin.display ?? '')
    }
  }

  return ''
}
