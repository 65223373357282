import { R4 } from '@ahryman40k/ts-fhir-types'
import { constant } from 'fp-ts/lib/Semigroup'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import { getLocalDateTimeFromISOString } from 'utils/dateUtil'
import {
  getExtensionValueOfUrl,
  getIdentifierBySystemType,
  getNameFromHumanName,
  getNameOfPatient,
  getValueCodableFromExtension,
} from 'utils/fhirResourcesHelper'
import { logger } from 'utils/logger'

export function getSuffixForDosage(selectedRouteOfAdmin: string): string {
  switch (selectedRouteOfAdmin) {
    case 'oral':
      return 'mg'
    case 'intravenous':
      return 'ml'
    case 'nasal':
      return 'ml'

    default:
      return ''
  }
}

export function getCDSUpdatedMedicationRequest(
  medicationRes: R4.IMedicationRequest,
  encounterRef: R4.IReference,
  appointment: FhirAppointmentDetail
): R4.IMedicationRequest {
  const resMedicationReq: R4.IMedicationRequest = { ...medicationRes }
  resMedicationReq.encounter = encounterRef
  resMedicationReq.recorder = {
    display: getNameFromHumanName(
      appointment.practitionerDetail.practitioner.name ?? []
    ),
    id: appointment.practitionerDetail.practitionerRole.id,
    reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
    type: appointment.practitionerDetail.practitionerRole.resourceType,
  }

  return resMedicationReq
}

export function getMedicationStatementFromWelloMedications(
  medication: WelloMedication,
  patient: R4.IPatient,
  encounter: R4.IEncounter,
  sequence: number
): R4.IMedicationStatement {
  //   if (medication.otherDataText) {
  //     otherCoding = {
  //       code: medication.medicationCode.code,
  //       system: medication.medicationCode.system,
  //       display: medication.otherDataText ?? '',
  //     }
  //   }

  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const ayurvedaExtension: R4.IExtension[] = []
  ayurvedaExtension.push({
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-med-recorder-ext',
    valueReference: {
      display: getNameFromHumanName(pract.name ?? []),
      reference: `${practRole.resourceType}/${practRole.id}`,
      id: practRole.id,
      type: practRole.resourceType,
    },
  })

  if (medication.aushadhaKalaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext',
      valueCodeableConcept: {
        coding: [medication.aushadhaKalaCode],
        text: medication.aushadhaKalaCode.display,
      },
    })
  }

  if (medication.anupanaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext',
      valueCodeableConcept: {
        coding: [medication.anupanaCode],
        text: medication.anupanaCode.display,
      },
    })
  }

  if (
    medication.anupanaDosageQuantity &&
    medication.anupanaDosageQuantityType
  ) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext',
      valueDosage: {
        doseAndRate: [
          {
            doseQuantity: {
              value: medication.anupanaDosageQuantity,
              unit: medication.anupanaDosageQuantityType,
            },
          },
        ],
      },
    })
  }

  const dosageInstruction: R4.IDosage = {
    sequence,
    timing: {
      repeat: {
        boundsPeriod: {
          start: medication.startFrom
            ? medication.startFrom.toISOString()
            : undefined,
          end: medication.till ? medication.till.toISOString() : undefined,
        },

        when: getEventCodeForTimingFromMedication(medication),
      },
      code: {
        text: 'Every Day at institution specified times',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
            code: 'QD',
            display: 'Every Day at institution specified times',
          },
        ],
      },
    },
    route: medication.administrationCode
      ? getCodableConceptOfRouteOfAdmin(medication.administrationCode)
      : undefined,
    doseAndRate: [
      {
        doseQuantity: {
          value: medication.dosageQuantity,
          unit: medication.dosageQuantityType,
        },
      },
    ],
  }

  if (medication.type === 'ayurveda') {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext',
      valueCodeableConcept: {
        text: 'Ayurveda',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/umls',
            display: 'Ayurveda',
            code: 'C0025122',
          },
          {
            system: 'http://snomed.info/sct',
            display: 'Ayurveda',
            code: '452091000124101',
          },
        ],
      },
    })
  }

  const noteData: R4.IAnnotation[] = []
  if (medication.notes) {
    if (medication.notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${pract.resourceType}/${pract.id}`,
        },
        text: medication.notes,
        time: new Date().toISOString(),
      })
    }
  }

  const medicationRequest: R4.IMedicationStatement = {
    resourceType: 'MedicationStatement',
    meta: {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndMedicationStatementBase',
      ],
    },

    status: 'active',
    // category: {
    //   coding: [
    //     {
    //       system:
    //         'http://terminology.hl7.org/CodeSystem/medication-statement-category',
    //       code: 'inpatient',
    //       display: 'Inpatient',
    //     },
    //   ],
    // },
    subject: {
      display: getNameOfPatient(patient),
      reference: `${patient.resourceType}/${patient.id}`,
      id: patient.id,
      type: patient.resourceType,
    },
    informationSource: {
      display: getNameOfPatient(patient),
      reference: `${patient.resourceType}/${patient.id}`,
      id: patient.id,
      type: patient.resourceType,
    },

    /* medicationReference: {
      display: medication.medication
        ? medication.medication.code
          ? medication.medication.code.text ?? ''
          : ''
        : '',
      identifier: medication.medication
        ? getIdentifierBySystemType(
            medication.medication.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Id/medication'
          )
        : undefined,
    }, */
    dosage: [dosageInstruction],
    note: noteData,
    effectiveDateTime: moment().toISOString(),
  }

  if (encounter) {
    medicationRequest.context = {
      reference: `${encounter.resourceType}/${encounter.id}`,
      id: encounter.id,
      type: encounter.resourceType,
    }
  }

  if (medication.medicationCode) {
    medicationRequest.medicationCodeableConcept = {
      coding: [medication.medicationCode],
    }
  }

  if (medication.medication) {
    medicationRequest.medicationReference = {
      display: medication.medication
        ? medication.medication.code
          ? medication.medication.code.text ?? ''
          : ''
        : '',
      identifier: medication.medication
        ? getIdentifierBySystemType(
            medication.medication.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Id/medication'
          )
        : undefined,
    }
    const categoryData = getValueCodableFromExtension(
      medication.medication.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-medicine-category-ext'
    )

    if (categoryData) {
      medicationRequest.category = categoryData
    } else {
      medicationRequest.category = {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/medication-statement-category',
            code: 'inpatient',
            display: 'Inpatient',
          },
        ],
      }
    }
  } else {
    medicationRequest.category = {
      coding: [
        {
          system:
            'http://terminology.hl7.org/CodeSystem/medication-statement-category',
          code: 'inpatient',
          display: 'Inpatient',
        },
      ],
    }
  }

  if (ayurvedaExtension.length > 0) {
    medicationRequest.extension = ayurvedaExtension
  }

  return medicationRequest
}

export function getMedicationStatementFromWelloMedicationsOPD(
  medication: WelloMedication,
  appointment: FhirAppointmentDetail,
  sequence: number
): R4.IMedicationStatement {
  const ayurvedaExtension: R4.IExtension[] = []

  ayurvedaExtension.push({
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-med-recorder-ext',
    valueReference: {
      display: getNameFromHumanName(
        appointment.practitionerDetail.practitioner.name ?? []
      ),
      reference: `${appointment.practitionerDetail.practitioner.resourceType}/${appointment.practitionerDetail.practitioner.id}`,
      id: appointment.practitionerDetail.practitioner.id,
      type: appointment.practitionerDetail.practitioner.resourceType,
    },
  })

  if (medication.aushadhaKalaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext',
      valueCodeableConcept: {
        coding: [medication.aushadhaKalaCode],
        text: medication.aushadhaKalaCode.display,
      },
    })
  }

  if (medication.anupanaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext',
      valueCodeableConcept: {
        coding: [medication.anupanaCode],
        text: medication.anupanaCode.display,
      },
    })
  }

  if (
    medication.anupanaDosageQuantity &&
    medication.anupanaDosageQuantityType
  ) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext',
      valueDosage: {
        doseAndRate: [
          {
            doseQuantity: {
              value: medication.anupanaDosageQuantity,
              unit: medication.anupanaDosageQuantityType,
            },
          },
        ],
      },
    })
  }
  const dosageInstruction: R4.IDosage = {
    sequence,
    timing: {
      repeat: {
        boundsPeriod: {
          start: medication.startFrom
            ? medication.startFrom.toISOString()
            : undefined,
          end: medication.till ? medication.till.toISOString() : undefined,
        },

        when: getEventCodeForTimingFromMedication(medication),
      },
      code: {
        text: 'Every Day at institution specified times',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
            code: 'QD',
            display: 'Every Day at institution specified times',
          },
        ],
      },
    },
    route: medication.administrationCode
      ? getCodableConceptOfRouteOfAdmin(medication.administrationCode)
      : undefined,
    doseAndRate: [
      {
        doseQuantity: {
          value: medication.dosageQuantity,
          unit: medication.dosageQuantityType,
        },
      },
    ],
  }

  const medicationCodes: any = medication.medicationCode

  const medicationRequest: R4.IMedicationStatement = {
    resourceType: 'MedicationStatement',
    meta: {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndMedicationStatementBase',
      ],
    },

    status: 'active',
    category: {
      coding: [
        {
          system:
            'http://terminology.hl7.org/CodeSystem/medication-statement-category',
          code: 'inpatient',
          display: 'Inpatient',
        },
      ],
    },
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },

    /*  medicationReference: {
      display: medication.medication
        ? medication.medication.code
          ? medication.medication.code.text ?? ''
          : ''
        : '',
      identifier: medication.medication
        ? getIdentifierBySystemType(
            medication.medication.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Id/medication'
          )
        : undefined,
    }, */
    dosage: [dosageInstruction],
    effectiveDateTime: moment().toISOString(),
  }

  if (appointment.encounter) {
    medicationRequest.context = {
      reference: `${appointment.encounter.resourceType}/${appointment.encounter.id}`,
      id: appointment.encounter.id,
      type: appointment.encounter.resourceType,
    }
  }

  if (medication.medicationCode) {
    medicationRequest.medicationCodeableConcept = {
      coding: [medication.medicationCode],
    }
  }

  if (medication.medication) {
    medicationRequest.medicationReference = {
      display: medication.medication
        ? medication.medication.code
          ? medication.medication.code.text ?? ''
          : ''
        : '',
      identifier: medication.medication
        ? getIdentifierBySystemType(
            medication.medication.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Id/medication'
          )
        : undefined,
    }
  }

  if (ayurvedaExtension.length > 0) {
    medicationRequest.extension = ayurvedaExtension
  }
  return medicationRequest
}

function getRxNormOfMedicine(code: R4.ICoding) {
  const rxCodes: R4.ICoding[] = [
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '314231',
      display: 'Simvastatin 10 MG Oral Tablet',
    },
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '314076',
      display: 'lisinopril 10 MG Oral Tablet',
    },
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '310436',
      display: 'Galantamine 4 MG Oral Tablet',
    },
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '904419',
      display: 'Alendronic acid 10 MG Oral Tablet',
    },
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '310798',
      display: 'Hydrochlorothiazide 25 MG Oral Tablet',
    },
  ]
  let res: R4.ICoding | undefined
  if (code.display) {
    const resIndex = rxCodes.findIndex((e) => e.display === code.display)

    if (resIndex > -1) return rxCodes[resIndex]
  }
  return undefined
}

function getCodableConceptOfRouteOfAdmin(
  routeOfAdminStr: String
): R4.ICodeableConcept {
  switch (routeOfAdminStr) {
    case 'oral':
    case '26643006':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '26643006',
            display: 'Oral use',
          },
        ],
      }
    case 'Vaginal (Through the Vagina)':
    case '16857009':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '16857009',
            display: 'Vaginal (Through the Vagina)',
          },
        ],
      }
    case 'Urethral (Through the Urethra)':
    case '90028008':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '90028008',
            display: 'Urethral (Through the Urethra)',
          },
        ],
      }
    case 'Intravenous':
    case '47625008':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '47625008',
            display: 'Intravenous use',
          },
        ],
      }
    case 'nasal':
    case '46713006':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '46713006',
            display: 'Nasal use',
          },
        ],
      }
    case 'opthalmic':
    case '54485002':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '54485002',
            display: 'Ophthalmic route',
          },
        ],
      }
    case 'subcutaneous':
    case '34206005':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '34206005',
            display: 'Subcutaneous route',
          },
        ],
      }

    case 'topical':
    case '6064005':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '6064005',
            display: 'Topical route',
          },
        ],
      }

    case 'Buccal':
    case '54471007':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '54471007',
            display: 'Buccal route',
          },
        ],
      }

    case 'Intramuscular':
    case '78421000':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '78421000',
            display: 'Intramuscular route',
          },
        ],
      }

    case 'Otic':
    case '10547007':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '10547007',
            display: 'Otic route',
          },
        ],
      }

    case 'Per Rectum':
    case '37161004':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '37161004',
            display: 'Per rectum',
          },
        ],
      }

    default:
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '421134003',
            display: 'Inhale',
          },
        ],
      }
  }
}

function getEventCodeForTimingFromMedication(
  medication: WelloMedication
): R4.Timing_RepeatWhenKind[] {
  const codes: R4.Timing_RepeatWhenKind[] = []
  if (medication.timeOfDayCode)
    medication.timeOfDayCode.forEach((eachTod) => {
      switch (eachTod) {
        case 'morning':
          if (medication.mealCode === 'before')
            codes.push(R4.Timing_RepeatWhenKind._acm)

          if (medication.mealCode === 'after')
            codes.push(R4.Timing_RepeatWhenKind._pcm)
          if (medication.mealCode === 'anytime')
            codes.push(R4.Timing_RepeatWhenKind._cm)
          break
        case 'afternoon':
          if (medication.mealCode === 'before')
            codes.push(R4.Timing_RepeatWhenKind._acd)

          if (medication.mealCode === 'after')
            codes.push(R4.Timing_RepeatWhenKind._pcd)
          if (medication.mealCode === 'anytime')
            codes.push(R4.Timing_RepeatWhenKind._cd)
          break
        case 'evening':
          if (medication.mealCode === 'before')
            codes.push(R4.Timing_RepeatWhenKind._ac)

          if (medication.mealCode === 'after')
            codes.push(R4.Timing_RepeatWhenKind._pc)
          if (medication.mealCode === 'anytime')
            codes.push(R4.Timing_RepeatWhenKind._c)
          break
        case 'night':
          if (medication.mealCode === 'before')
            codes.push(R4.Timing_RepeatWhenKind._acv)

          if (medication.mealCode === 'after')
            codes.push(R4.Timing_RepeatWhenKind._pcv)
          if (medication.mealCode === 'anytime')
            codes.push(R4.Timing_RepeatWhenKind._cv)
          break

        default:
          break
      }
    })

  return codes
}

function getCategory(category: string): string {
  let cat: string = ''
  if (category)
    switch (category) {
      case 'food':
        cat = 'Food'
        break

      default:
        break
    }

  return cat
}

export function createBundleObjectForWelloMedications(
  patient: R4.IPatient,
  encounter: R4.IEncounter,
  medications: WelloMedication[]
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const allergyDetails: R4.IMedicationStatement[] = medications.map(
    (e, index) =>
      getMedicationStatementFromWelloMedications(e, patient, encounter, index)
  )

  const bundleEntries: R4.IBundle_Entry[] = allergyDetails.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function createBundleObjectForWelloMedicationsOPD(
  appointment: FhirAppointmentDetail,
  medications: WelloMedication[]
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const allergyDetails: R4.IMedicationStatement[] = medications.map(
    (e, index) =>
      getMedicationStatementFromWelloMedicationsOPD(e, appointment, index)
  )

  const bundleEntries: R4.IBundle_Entry[] = allergyDetails.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function createBundleObjectForRequests(
  appointment: FhirAppointmentDetail,
  encounter: R4.IEncounter,
  medications: R4.IMedicationRequest[]
): R4.IBundle {
  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }

  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
    type: encounter.resourceType,
  }
  const allergyDetails: R4.IMedicationRequest[] = medications.map((e, index) =>
    getCDSUpdatedMedicationRequest(e, encounterRef, appointment)
  )

  const bundleEntries: R4.IBundle_Entry[] = allergyDetails.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })

  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function getWelloMedicationsFromBundleResponses(
  bundleResponse: R4.IBundle
): WelloMedication[] {
  let welloMedications: WelloMedication[] = []

  if (bundleResponse.entry) {
    if (bundleResponse.entry.length > 0) {
      welloMedications = bundleResponse.entry.map((e) =>
        getWelloMedicationFromMedicationRequest(
          e.resource as R4.IMedicationRequest
        )
      )
    }
  }

  return welloMedications
}

export function getWelloMedicationFromMedicationRequest(
  medicationRequest: R4.IMedicationRequest
): WelloMedication {
  const medData: R4.IMedication = {
    resourceType: 'Medication',
  }
  const welloMedication: WelloMedication = {
    id: medicationRequest.id ?? '',
    base: 'request',
    medication: medData,
    medicationCode: medicationRequest.medicationCodeableConcept?.coding![0]!,
    rxNormCode: medicationRequest.medicationCodeableConcept?.coding![1]!,
    administrationCode: medicationRequest.dosageInstruction![0].route
      ? getRouteOfAdminCodeFromMedicationRequest(
          medicationRequest.dosageInstruction![0].route!
        )
      : undefined,
    dosageQuantity: medicationRequest.dosageInstruction![0].doseAndRate
      ? medicationRequest.dosageInstruction![0].doseAndRate![0].doseQuantity!
          .value!
      : undefined,
    dosageQuantityType: medicationRequest.dosageInstruction![0].doseAndRate
      ? medicationRequest.dosageInstruction![0].doseAndRate![0].doseQuantity!
          .unit!
      : undefined,
    mealCode: medicationRequest.dosageInstruction![0].timing
      ? getMealFromMedicationRequest(
          medicationRequest.dosageInstruction![0].timing!.repeat!.when![0]
        )
      : undefined,
    timeOfDayCode: medicationRequest.dosageInstruction![0].timing
      ? getTimeOfDay(
          medicationRequest.dosageInstruction![0].timing!.repeat!.when!
        )
      : undefined,
    startFrom: medicationRequest.dosageInstruction![0].timing
      ? getLocalDateTimeFromISOString(
          medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
            ?.start!
        )
      : undefined,
    till: medicationRequest.dosageInstruction![0].timing
      ? getLocalDateTimeFromISOString(
          medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
            ?.end!
        )
      : undefined,
    textForm: medicationRequest.dosageInstruction![0].text,
    medicationDate: new Date(),
  }

  const oushadhaKalaExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext'
  )
  if (
    oushadhaKalaExtension &&
    oushadhaKalaExtension.valueCodeableConcept &&
    oushadhaKalaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.aushadhaKalaCode =
      oushadhaKalaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext'
  )
  if (
    anupanaExtension &&
    anupanaExtension.valueCodeableConcept &&
    anupanaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.anupanaCode =
      anupanaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaDosageExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext'
  )
  if (
    anupanaDosageExtension &&
    anupanaDosageExtension.valueDosage &&
    anupanaDosageExtension.valueDosage.doseAndRate &&
    anupanaDosageExtension.valueDosage.doseAndRate[0] &&
    anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity
  ) {
    welloMedication.anupanaDosageQuantity =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.value
    welloMedication.anupanaDosageQuantityType =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.unit
  }

  return welloMedication
}

export function getRouteOfAdminCodeFromMedicationRequest(
  codeableConcept: R4.ICodeableConcept
) {
  const code: string = codeableConcept.coding![0].code!

  switch (code) {
    case '26643006':
      return 'oral'

    case '47625008':
      return 'intravenous'
    case '46713006':
      return 'nasal'
    default:
      return 'NA'
  }
}

export function getMealFromMedicationRequest(
  codeableConcept: R4.Timing_RepeatWhenKind
) {
  switch (codeableConcept) {
    case R4.Timing_RepeatWhenKind._acm:
    case R4.Timing_RepeatWhenKind._ac:
    case R4.Timing_RepeatWhenKind._acd:
    case R4.Timing_RepeatWhenKind._acv:
      return 'before'
    case R4.Timing_RepeatWhenKind._pcd:
    case R4.Timing_RepeatWhenKind._pc:
    case R4.Timing_RepeatWhenKind._pcv:
    case R4.Timing_RepeatWhenKind._pcm:
      return 'after'
    case R4.Timing_RepeatWhenKind._cm:
    case R4.Timing_RepeatWhenKind._c:
    case R4.Timing_RepeatWhenKind._cd:
    case R4.Timing_RepeatWhenKind._cv:
      return 'anytime'

    default:
      return 'NA'
  }
}

export function getTimeOfDay(
  codeableConcept: R4.Timing_RepeatWhenKind[]
): string[] {
  const codes: string[] = []

  codeableConcept.forEach((e) => {
    switch (e) {
      case R4.Timing_RepeatWhenKind._acm:
      case R4.Timing_RepeatWhenKind._pcm:
      case R4.Timing_RepeatWhenKind._cm:
        codes.push('morning')
        break
      case R4.Timing_RepeatWhenKind._ac:
      case R4.Timing_RepeatWhenKind._pc:
      case R4.Timing_RepeatWhenKind._c:
        codes.push('evening')
        break
      case R4.Timing_RepeatWhenKind._acd:
      case R4.Timing_RepeatWhenKind._pcd:
      case R4.Timing_RepeatWhenKind._cd:
        codes.push('afternoon')
        break
      case R4.Timing_RepeatWhenKind._acv:
      case R4.Timing_RepeatWhenKind._pcv:
      case R4.Timing_RepeatWhenKind._cv:
        codes.push('night')
        break

      default:
    }
  })
  return codes
}

export function getMealStatus(codeableConcept: R4.ICodeableConcept) {
  const code: string = codeableConcept.coding![0].code!

  switch (code) {
    case '26643006':
      return 'oral'

    case '47625008':
      return 'intravenous'
    case '46713006':
      return 'nasal'
    default:
      return 'NA'
  }
}

export function getMedicationStatementFromWelloMedicationForUpdate(
  medication: WelloMedication,
  patient: R4.IPatient,
  encounter: R4.IEncounter,
  sequence: number
): R4.IMedicationStatement {
  //   if (medication.otherDataText) {
  //     otherCoding = {
  //       code: medication.medicationCode.code,
  //       system: medication.medicationCode.system,
  //       display: medication.otherDataText ?? '',
  //     }
  //   }

  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const ayurvedaExtension: R4.IExtension[] = []
  ayurvedaExtension.push({
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-med-recorder-ext',
    valueReference: {
      display: getNameFromHumanName(pract.name ?? []),
      reference: `${practRole.resourceType}/${practRole.id}`,
      id: practRole.id,
      type: practRole.resourceType,
    },
  })

  if (medication.aushadhaKalaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext',
      valueCodeableConcept: {
        coding: [medication.aushadhaKalaCode],
        text: medication.aushadhaKalaCode.display,
      },
    })
  }

  if (medication.anupanaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext',
      valueCodeableConcept: {
        coding: [medication.anupanaCode],
        text: medication.anupanaCode.display,
      },
    })
  }

  if (
    medication.anupanaDosageQuantity &&
    medication.anupanaDosageQuantityType
  ) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext',
      valueDosage: {
        doseAndRate: [
          {
            doseQuantity: {
              value: medication.anupanaDosageQuantity,
              unit: medication.anupanaDosageQuantityType,
            },
          },
        ],
      },
    })
  }

  const dosageInstruction: R4.IDosage = {
    sequence,
    timing: {
      repeat: {
        boundsPeriod: {
          start: medication.startFrom
            ? medication.startFrom.toISOString()
            : undefined,
          end: medication.till ? medication.till.toISOString() : undefined,
        },

        when: getEventCodeForTimingFromMedication(medication),
      },
      code: {
        text: 'Every Day at institution specified times',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
            code: 'QD',
            display: 'Every Day at institution specified times',
          },
        ],
      },
    },
    route: medication.administrationCode
      ? getCodableConceptOfRouteOfAdmin(medication.administrationCode)
      : undefined,
    doseAndRate: [
      {
        doseQuantity: {
          value: medication.dosageQuantity,
          unit: medication.dosageQuantityType,
        },
      },
    ],
  }

  if (medication.type === 'ayurveda') {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext',
      valueCodeableConcept: {
        text: 'Ayurveda',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/umls',
            display: 'Ayurveda',
            code: 'C0025122',
          },
          {
            system: 'http://snomed.info/sct',
            display: 'Ayurveda',
            code: '452091000124101',
          },
        ],
      },
    })
  }

  const noteData: R4.IAnnotation[] = []
  if (medication.notes) {
    if (medication.notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${pract.resourceType}/${pract.id}`,
        },
        text: medication.notes,
        time: new Date().toISOString(),
      })
    }
  }

  const medicationRequest: R4.IMedicationStatement = {
    resourceType: 'MedicationStatement',
    meta: {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndMedicationStatementBase',
      ],
    },

    status: 'active',
    // category: {
    //   coding: [
    //     {
    //       system:
    //         'http://terminology.hl7.org/CodeSystem/medication-statement-category',
    //       code: 'inpatient',
    //       display: 'Inpatient',
    //     },
    //   ],
    // },
    subject: {
      display: getNameOfPatient(patient),
      reference: `${patient.resourceType}/${patient.id}`,
      id: patient.id,
      type: patient.resourceType,
    },
    informationSource: {
      display: getNameOfPatient(patient),
      reference: `${patient.resourceType}/${patient.id}`,
      id: patient.id,
      type: patient.resourceType,
    },

    /* medicationReference: {
        display: medication.medication
          ? medication.medication.code
            ? medication.medication.code.text ?? ''
            : ''
          : '',
        identifier: medication.medication
          ? getIdentifierBySystemType(
              medication.medication.identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Id/medication'
            )
          : undefined,
      }, */
    dosage: [dosageInstruction],
    note: noteData,
    effectiveDateTime: moment().toISOString(),
  }

  if (encounter) {
    medicationRequest.context = {
      reference: `${encounter.resourceType}/${encounter.id}`,
      id: encounter.id,
      type: encounter.resourceType,
    }
  }

  if (medication.medicationCode) {
    medicationRequest.medicationCodeableConcept = {
      coding: [medication.medicationCode],
    }
  }

  if (medication.medication) {
    medicationRequest.medicationReference = {
      display: medication.medication
        ? medication.medication.code
          ? medication.medication.code.text ?? ''
          : ''
        : '',
      identifier: medication.medication
        ? getIdentifierBySystemType(
            medication.medication.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Id/medication'
          )
        : undefined,
    }
    const categoryData = getValueCodableFromExtension(
      medication.medication.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-medicine-category-ext'
    )

    if (categoryData) {
      medicationRequest.category = categoryData
    } else {
      medicationRequest.category = {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/medication-statement-category',
            code: 'inpatient',
            display: 'Inpatient',
          },
        ],
      }
    }
  } else {
    medicationRequest.category = {
      coding: [
        {
          system:
            'http://terminology.hl7.org/CodeSystem/medication-statement-category',
          code: 'inpatient',
          display: 'Inpatient',
        },
      ],
    }
  }

  if (ayurvedaExtension.length > 0) {
    medicationRequest.extension = ayurvedaExtension
  }

  return medicationRequest
}
