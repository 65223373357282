import { R4 } from '@ahryman40k/ts-fhir-types'
import { constant } from 'fp-ts/lib/Semigroup'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import { getPriority } from 'os'
import { FHIRApiClient } from 'services/fhirApiServices'
import { InventoryClient } from 'services/inventoryService'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserUnitDetails,
} from 'services/userDetailsService'
import { getLocalDateTimeFromISOString } from 'utils/dateUtil'
import {
  getAddress,
  getCodeOfSystemFromCodableConcept,
  getCodeOfSystemFromCodableConceptArray,
  getDefaultCode,
  getDefaultCodeOfSystemFromCodableConceptList,
  getExtensionCodingOfUrlForExam,
  getExtensionValueOfUrl,
  getIdentifierBySystemType,
  getNameFromHumanName,
  getNameOfPatient,
} from 'utils/fhirResourcesHelper'
import {
  getNotesString,
  hasNotesForChiefForNote,
  hasNotesForChiefForPatient,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { logger } from 'utils/logger'
import {
  getAddressesForDisplay,
  getPatientForDisplay,
} from 'utils/medicineHelper/cart-apis'

export function getSuffixForDosage(selectedRouteOfAdmin: string): string {
  switch (selectedRouteOfAdmin) {
    case 'oral':
      return 'mg'
    case 'intravenous':
      return 'ml'
    case 'nasal':
      return 'ml'

    default:
      return ''
  }
}

export function getCDSUpdatedMedicationRequest(
  medicationRes: R4.IMedicationRequest,
  encounterRef: R4.IReference,
  appointment: FhirAppointmentDetail
): R4.IMedicationRequest {
  const resMedicationReq: R4.IMedicationRequest = { ...medicationRes }
  resMedicationReq.encounter = encounterRef
  resMedicationReq.authoredOn = new Date().toISOString()
  resMedicationReq.recorder = {
    display: getNameFromHumanName(
      appointment.practitionerDetail.practitioner.name ?? []
    ),
    id: appointment.practitionerDetail.practitionerRole.id,
    reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
    type: appointment.practitionerDetail.practitionerRole.resourceType,
  }

  return resMedicationReq
}

export function getMedicationRequestFromWelloMedications(
  medication: WelloMedication,
  appointment: FhirAppointmentDetail,
  encounterRef: R4.IReference,
  sequence: number,
  carePlan?: R4.ICarePlan
): R4.IMedicationRequest {
  const ayurvedaExtension: R4.IExtension[] = []

  if (medication.aushadhaKalaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext',
      valueCodeableConcept: {
        coding: [medication.aushadhaKalaCode],
        text: medication.aushadhaKalaCode.display,
      },
    })
  }

  if (medication.anupanaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext',
      valueCodeableConcept: {
        coding: [medication.anupanaCode],
        text: medication.anupanaCode.display,
      },
    })
  }

  if (
    medication.anupanaDosageQuantity &&
    medication.anupanaDosageQuantityType
  ) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext',
      valueDosage: {
        doseAndRate: [
          {
            doseQuantity: {
              value: medication.anupanaDosageQuantity,
              unit: medication.anupanaDosageQuantityType,
            },
          },
        ],
      },
    })
  }

  if (medication.type === 'ayurveda') {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext',
      valueCodeableConcept: {
        text: 'Ayurveda',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/umls',
            display: 'Ayurveda',
            code: 'C0025122',
          },
          {
            system: 'http://snomed.info/sct',
            display: 'Ayurveda',
            code: '452091000124101',
          },
        ],
      },
    })
  }

  const dosageInstruction: R4.IDosage = {
    sequence,
    timing: {
      repeat: {
        boundsPeriod: {
          start: medication.startFrom
            ? medication.startFrom.toISOString()
            : undefined,
          end: medication.till ? medication.till.toISOString() : undefined,
        },

        when:
          medication.type !== 'ayurveda'
            ? getEventCodeForTimingFromMedication(medication)
            : undefined,
      },
      code: {
        text: 'Every Day at institution specified times',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
            code: 'QD',
            display: 'Every Day at institution specified times',
          },
        ],
      },
    },
    route: medication.administrationCode
      ? getCodableConceptOfRouteOfAdmin(medication.administrationCode)
      : undefined,
    doseAndRate: [
      {
        doseQuantity: {
          value: medication.dosageQuantity,
          unit: medication.dosageQuantityType,
        },
      },
    ],
  }

  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()

  const noteData: R4.IAnnotation[] = []
  if (medication.notes) {
    if (medication.notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${pract.resourceType}/${pract.id}`,
        },
        text: medication.notes,
        time: new Date().toISOString(),
      })
    }
  }

  const medicationRequest: R4.IMedicationRequest = {
    resourceType: 'MedicationRequest',
    status: 'active',
    intent: 'order',
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/medicationrequest-category',
            code: 'outpatient',
            display: 'Outpatient',
          },
        ],
      },
    ],
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },

    encounter: encounterRef,
    requester: {
      display: getNameFromHumanName(
        appointment.practitionerDetail.practitioner.name ?? []
      ),
      id: appointment.practitionerDetail.practitionerRole.id,
      reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
      type: appointment.practitionerDetail.practitionerRole.resourceType,
    },
    recorder: {
      display: getNameFromHumanName(
        appointment.practitionerDetail.practitioner.name ?? []
      ),
      id: appointment.practitionerDetail.practitionerRole.id,
      reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
      type: appointment.practitionerDetail.practitionerRole.resourceType,
    },
    medicationReference: {
      display: medication.medication
        ? medication.medication.code
          ? medication.medication.code.text ?? ''
          : ''
        : '',
      identifier: medication.medication
        ? getIdentifierBySystemType(
            medication.medication.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Id/medication'
          )
        : undefined,
    },
    note: noteData,
    // medicationCodeableConcept: {
    //   text: medication!.medicationCode.display,
    //   coding: medicationCodes,
    // },
    dosageInstruction: [dosageInstruction],
  }

  if (ayurvedaExtension.length > 0) {
    medicationRequest.extension = ayurvedaExtension
  }
  if (carePlan) {
    medicationRequest.basedOn = [
      {
        reference: `CarePlan/${carePlan.id}`,
      },
    ]
  }
  return medicationRequest
}

export function getMedicationRequestFromWelloMedicationsWithoutEncounter(
  medication: WelloMedication,
  appointment: FhirAppointmentDetail,
  sequence: number
): R4.IMedicationRequest {
  const ayurvedaExtension: R4.IExtension[] = []

  if (medication.aushadhaKalaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext',
      valueCodeableConcept: {
        coding: [medication.aushadhaKalaCode],
        text: medication.aushadhaKalaCode.display,
      },
    })
  }

  if (medication.anupanaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext',
      valueCodeableConcept: {
        coding: [medication.anupanaCode],
        text: medication.anupanaCode.display,
      },
    })
  }

  if (
    medication.anupanaDosageQuantity &&
    medication.anupanaDosageQuantityType
  ) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext',
      valueDosage: {
        doseAndRate: [
          {
            doseQuantity: {
              value: medication.anupanaDosageQuantity,
              unit: medication.anupanaDosageQuantityType,
            },
          },
        ],
      },
    })
  }

  const dosageInstruction: R4.IDosage = {
    sequence,
    timing: {
      repeat: {
        boundsPeriod: {
          start: medication.startFrom
            ? medication.startFrom.toISOString()
            : undefined,
          end: medication.till ? medication.till.toISOString() : undefined,
        },

        when: getEventCodeForTimingFromMedication(
          medication as WelloMedication
        ),
      },
      code: {
        text: 'Every Day at institution specified times',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
            code: 'QD',
            display: 'Every Day at institution specified times',
          },
        ],
      },
    },
    route: medication.administrationCode
      ? getCodableConceptOfRouteOfAdmin(medication.administrationCode)
      : undefined,
    doseAndRate: [
      {
        doseQuantity: {
          value: medication.dosageQuantity,
          unit: medication.dosageQuantityType,
        },
      },
    ],
  }

  const practRole: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const pract: R4.IPractitioner = getCurrentUserPractitionerDetails()

  const noteData: R4.IAnnotation[] = []
  if (medication.notes) {
    if (medication.notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${pract.resourceType}/${pract.id}`,
        },
        text: medication.notes,
        time: new Date().toISOString(),
      })
    }
  }

  const medicationRequest: R4.IMedicationRequest = {
    resourceType: 'MedicationRequest',
    status: 'active',
    intent: 'order',
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/medicationrequest-category',
            code: 'outpatient',
            display: 'Outpatient',
          },
        ],
      },
    ],
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },

    requester: {
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },
    recorder: {
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },

    note: noteData,
    // medicationCodeableConcept: {
    //   text: medication!.medicationCode.display,
    //   coding: medicationCodes,
    // },
    dosageInstruction: [dosageInstruction],
  }
  if (medication.medicationCode) {
    medicationRequest.medicationCodeableConcept = {
      text: medication.medicationCode.display,
      coding: [medication.medicationCode],
    }
  } else {
    medicationRequest.medicationReference = {
      display: medication.medication
        ? medication.medication.code
          ? medication.medication.code.text ?? ''
          : ''
        : '',
      identifier: medication.medication
        ? getIdentifierBySystemType(
            medication.medication.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Id/medication'
          )
        : undefined,
    }
  }

  if (medication.type === 'ayurveda') {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext',
      valueCodeableConcept: {
        text: 'Ayurveda',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/umls',
            display: 'Ayurveda',
            code: 'C0025122',
          },
          {
            system: 'http://snomed.info/sct',
            display: 'Ayurveda',
            code: '452091000124101',
          },
        ],
      },
    })
  }
  if (ayurvedaExtension.length > 0) {
    medicationRequest.extension = ayurvedaExtension
  }
  return medicationRequest
  /* const dosageInstruction: R4.IDosage = {
    sequence,
    text: `${medication.dosageQuantity} ${medication.dosageQuantityType}`,
    timing: {
      repeat: {
        boundsPeriod: {
          start: medication.startFrom
            ? medication.startFrom.toISOString()
            : undefined,
          end: medication.till ? medication.till.toISOString() : undefined,
        },

        when: getEventCodeForTimingFromMedication(medication),
      },
      code: {
        text: 'Every Day at institution specified times',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
            code: 'QD',
            display: 'Every Day at institution specified times',
          },
        ],
      },
    },
    route: medication.administrationCode
      ? getCodableConceptOfRouteOfAdmin(medication.administrationCode)
      : undefined,
    doseAndRate: [
      {
        doseQuantity: {
          value: medication.dosageQuantity,
          unit: medication.dosageQuantityType,
        },
      },
    ],
  }

  const medicationCodes: R4.ICoding[] = []
  if (medication.medicationCode) {
    medicationCodes.push(medication.medicationCode)
  }
  /* if (rxNormCoding) {
    medicationCodes.push(rxNormCoding)
  } 
  const medicationRequest: R4.IMedicationRequest = {
    resourceType: 'MedicationRequest',
    status: 'active',
    intent: 'order',
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/medicationrequest-category',
            code: 'outpatient',
            display: 'Outpatient',
          },
        ],
      },
    ],
    subject: {
      display: getNameOfPatient(appointment.patient),
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      id: appointment.patient.id,
      type: appointment.patient.resourceType,
    },

    requester: {
      display: getNameFromHumanName(
        appointment.practitionerDetail.practitioner.name ?? []
      ),
      id: appointment.practitionerDetail.practitionerRole.id,
      reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
      type: appointment.practitionerDetail.practitionerRole.resourceType,
    },
    recorder: {
      display: getNameFromHumanName(
        appointment.practitionerDetail.practitioner.name ?? []
      ),
      id: appointment.practitionerDetail.practitionerRole.id,
      reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
      type: appointment.practitionerDetail.practitionerRole.resourceType,
    },

    dosageInstruction: [dosageInstruction],
  }

  if (medicationCodes.length > 0) {
    medicationRequest.medicationCodeableConcept = {
      text: medicationCodes[0]?.display ?? '',
      coding: medicationCodes,
    }
  } else {
    medicationRequest.medicationReference = {
      display: medication.medication
        ? medication.medication.code
          ? medication.medication.code.text ?? ''
          : ''
        : '',
      identifier: medication.medication
        ? getIdentifierBySystemType(
            medication.medication.identifier ?? [],
            'http://wellopathy.com/fhir/india/core/Id/medication'
          )
        : undefined,
    }
  }

  return medicationRequest */
}

function getRxNormOfMedicine(code: R4.ICoding) {
  const rxCodes: R4.ICoding[] = [
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '314231',
      display: 'Simvastatin 10 MG Oral Tablet',
    },
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '314076',
      display: 'lisinopril 10 MG Oral Tablet',
    },
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '310436',
      display: 'Galantamine 4 MG Oral Tablet',
    },
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '904419',
      display: 'Alendronic acid 10 MG Oral Tablet',
    },
    {
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      code: '310798',
      display: 'Hydrochlorothiazide 25 MG Oral Tablet',
    },
  ]
  let res: R4.ICoding | undefined
  if (code.display) {
    const resIndex = rxCodes.findIndex((e) => e.display === code.display)

    if (resIndex > -1) return rxCodes[resIndex]
  }
  return undefined
}

export function getCodableConceptOfRouteOfAdmin(
  routeOfAdminStr: String
): R4.ICodeableConcept {
  switch (routeOfAdminStr) {
    case 'oral':
    case 'Oral':
    case '26643006':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '26643006',
            display: 'Oral (By Mouth)',
          },
        ],
      }
    case 'Intravenous':
    case '47625008':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '47625008',
            display: 'Intravenous (IV - Into Vein)',
          },
        ],
      }
    case 'nasal':
    case 'Nasal':
    case '46713006':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '46713006',
            display: 'Nasal (Through the Nose)',
          },
        ],
      }
    case 'opthalmic':
    case 'Opthalmic':
    case '54485002':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '54485002',
            display: 'Ophthalmic (In the Eye)',
          },
        ],
      }
    case 'subcutaneous':
    case 'Subcutaneous':
    case '34206005':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '34206005',
            display: 'Subcutaneous (SubQ - Under the Skin)',
          },
        ],
      }

    case 'topical':
    case 'Topical':
    case '6064005':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '6064005',
            display: 'Topical (External Application)',
          },
        ],
      }

    case 'Urethral route':
    case '90028008':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '90028008',
            display: 'Urethral (Through the Urethra)',
          },
        ],
      }

    case 'Vaginal route':
    case '16857009':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '16857009',
            display: 'Vaginal (Through the Vagina)',
          },
        ],
      }

    case 'Buccal':
    case '54471007':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '54471007',
            display: 'Buccal (Between Cheek and Gum)',
          },
        ],
      }

    case 'Intramuscular':
    case '78421000':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '78421000',
            display: 'Intramuscular (IM - Into Muscle)',
          },
        ],
      }

    case 'Otic':
    case '10547007':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '10547007',
            display: 'Otic (In the Ear)',
          },
        ],
      }

    case 'Per Rectum':
    case '37161004':
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '37161004',
            display: 'Rectal (By Rectum)',
          },
        ],
      }

    default:
      return {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '421134003',
            display: 'Inhale',
          },
        ],
      }
  }
}

export function getEventCodeForTimingFromMedication(
  medication: WelloMedication
): R4.Timing_RepeatWhenKind[] {
  const codes: R4.Timing_RepeatWhenKind[] = []
  if (medication.timeOfDayCode)
    medication.timeOfDayCode.forEach((eachTod) => {
      switch (eachTod) {
        case 'morning':
          if (
            medication.mealCode === 'before' ||
            medication.mealCode === 'Before'
          )
            codes.push(R4.Timing_RepeatWhenKind._acm)

          if (
            medication.mealCode === 'after' ||
            medication.mealCode === 'After'
          )
            codes.push(R4.Timing_RepeatWhenKind._pcm)
          if (
            medication.mealCode === 'anytime' ||
            medication.mealCode === 'Anytime'
          )
            codes.push(R4.Timing_RepeatWhenKind._cm)
          break
        case 'afternoon':
          if (
            medication.mealCode === 'before' ||
            medication.mealCode === 'Before'
          )
            codes.push(R4.Timing_RepeatWhenKind._acd)

          if (
            medication.mealCode === 'after' ||
            medication.mealCode === 'After'
          )
            codes.push(R4.Timing_RepeatWhenKind._pcd)
          if (
            medication.mealCode === 'anytime' ||
            medication.mealCode === 'Anytime'
          )
            codes.push(R4.Timing_RepeatWhenKind._cd)
          break
        case 'evening':
          if (
            medication.mealCode === 'before' ||
            medication.mealCode === 'Before'
          )
            codes.push(R4.Timing_RepeatWhenKind._ac)

          if (
            medication.mealCode === 'after' ||
            medication.mealCode === 'After'
          )
            codes.push(R4.Timing_RepeatWhenKind._pc)
          if (
            medication.mealCode === 'anytime' ||
            medication.mealCode === 'Anytime'
          )
            codes.push(R4.Timing_RepeatWhenKind._c)
          break
        case 'night':
          if (
            medication.mealCode === 'before' ||
            medication.mealCode === 'Before'
          )
            codes.push(R4.Timing_RepeatWhenKind._acv)

          if (
            medication.mealCode === 'after' ||
            medication.mealCode === 'After'
          )
            codes.push(R4.Timing_RepeatWhenKind._pcv)
          if (
            medication.mealCode === 'anytime' ||
            medication.mealCode === 'Anytime'
          )
            codes.push(R4.Timing_RepeatWhenKind._cv)
          break

        default:
          break
      }
    })

  return codes
}

export function createBundleObjectForWelloMedications(
  appointment: FhirAppointmentDetail,
  encounter: R4.IEncounter,
  medications: WelloMedication[],
  carePlan?: R4.ICarePlan
): R4.IBundle {
  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }
  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
    type: encounter.resourceType,
  }
  const allergyDetails: R4.IMedicationRequest[] = medications.map((e, index) =>
    getMedicationRequestFromWelloMedications(
      e,
      appointment,
      encounterRef,
      index,
      carePlan
    )
  )

  const bundleEntries: R4.IBundle_Entry[] = allergyDetails.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })
  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function createBundleObjectForRequests(
  appointment: FhirAppointmentDetail,
  encounter: R4.IEncounter,
  medications: R4.IMedicationRequest[]
): R4.IBundle {
  const matchString: string = `${encounter.resourceType}?appointment=${appointment.appointment.resourceType}/${appointment.appointment.id}`

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: 'urn:uuid:1232323232324',
        request: {
          url: matchString,
          method: R4.Bundle_RequestMethodKind._put,
        },
        resource: encounter,
      },
    ],
  }

  const encounterRef: R4.IReference = {
    reference: `${encounter.resourceType}/urn:uuid:1232323232324`,
    type: encounter.resourceType,
  }
  const allergyDetails: R4.IMedicationRequest[] = medications.map((e, index) =>
    getCDSUpdatedMedicationRequest(e, encounterRef, appointment)
  )

  const bundleEntries: R4.IBundle_Entry[] = allergyDetails.map((e) => {
    const newBundleEntry: R4.IBundle_Entry = {
      request: {
        method: R4.Bundle_RequestMethodKind._post,
        url: e.resourceType,
      },
      resource: e,
    }
    return newBundleEntry
  })

  requestBundle.entry?.push(...bundleEntries)
  return requestBundle
}

export function getWelloMedicationsFromBundleResponses(
  bundleResponse: R4.IBundle
): WelloMedication[] {
  let welloMedications: WelloMedication[] = []

  if (bundleResponse.entry) {
    if (bundleResponse.entry.length > 0) {
      welloMedications = bundleResponse.entry.map((e) =>
        getWelloMedicationFromMedicationRequest(
          e.resource as R4.IMedicationRequest
        )
      )
    }
  }

  return welloMedications
}

export function getWelloMedicationsFromBundleResponsesForHistory(
  bundleResponse: R4.IBundle,
  encounterId?: string
): WelloMedication[] {
  const welloMedications: WelloMedication[] = []

  if (bundleResponse.entry) {
    if (bundleResponse.entry.length > 0) {
      const allergiesData: R4.IMedicationRequest[] =
        bundleResponse.entry?.map(
          (item) => item.resource as R4.IMedicationRequest
        ) ?? []
      for (let i = 0; i < allergiesData.length; i++) {
        const currentEncounter =
          allergiesData[i].encounter?.reference?.split('/')[1]

        if (currentEncounter !== encounterId) {
          const medication: WelloMedication =
            getWelloMedicationFromMedicationRequestForHistory(allergiesData[i])
          welloMedications.push(medication)
        }
      }
      // //   welloMedications = bundleResponse.entry.map((e) =>
      // //     getWelloMedicationFromMedicationRequestForHistory(
      // //       e.resource as R4.IMedicationRequest,
      // //       encounterId
      // //     )
      //   )
    }
  }

  return welloMedications
}

export function getWelloMedicationsFromBundleResponsesFromStatement(
  bundleResponse: R4.IBundle
): WelloMedication[] {
  let welloMedications: WelloMedication[] = []

  console.log('---------------bundleResponse----------------', bundleResponse)

  if (bundleResponse.entry) {
    if (bundleResponse.entry.length > 0) {
      const filteredData = bundleResponse.entry.filter((e) => {
        if (
          e &&
          e.resource &&
          e.resource.resourceType === 'MedicationStatement'
        ) {
          const medicationStatement = e.resource as R4.IMedicationStatement
          if (
            medicationStatement.basedOn &&
            medicationStatement.basedOn.length > 0
          ) {
            const basedOn = medicationStatement.basedOn.map(
              (f) => f.reference?.split('/')[0]
            )
            if (basedOn.includes('MedicationRequest')) {
              return false
            }
          }
        }
        return true
      })
      const updatedBundle = { ...bundleResponse, entry: filteredData }

      console.log('---------------updatedBundle----------------', updatedBundle)
      welloMedications = updatedBundle.entry.map((e) =>
        getWelloMedicationFromMedicationStatement(
          e.resource as R4.IMedicationStatement
        )
      )
    }
  }

  return welloMedications
}

export function getWelloMedicationFromMedicationStatement(
  medicationStatement: R4.IMedicationStatement
): WelloMedication {
  const welloMedication: WelloMedication = {
    id: medicationStatement.id ?? '',
    base: 'statement',
    medicationStatement,
    medicationCode: medicationStatement.medicationCodeableConcept?.coding![0]!,
    rxNormCode: medicationStatement.medicationCodeableConcept?.coding![1]!,
    administrationCode: medicationStatement.dosage
      ? medicationStatement.dosage![0].route
        ? getRouteOfAdminCodeFromMedicationRequest(
            medicationStatement.dosage![0].route!
          )
        : undefined
      : undefined,
    dosageQuantity: medicationStatement.dosage
      ? medicationStatement.dosage![0].doseAndRate
        ? medicationStatement.dosage![0].doseAndRate![0].doseQuantity!.value!
        : undefined
      : undefined,

    dosageQuantityType: medicationStatement.dosage
      ? medicationStatement.dosage![0].doseAndRate
        ? getUnitOfDosageFromRequest(
            medicationStatement.dosage![0].doseAndRate![0].doseQuantity!.unit!
          )
        : undefined
      : undefined,
    mealCode: medicationStatement.dosage
      ? medicationStatement.dosage && medicationStatement.dosage.length > 0
        ? medicationStatement.dosage![0].timing
          ? medicationStatement.dosage![0].timing!.repeat!.when
            ? getMealFromMedicationRequest(
                medicationStatement.dosage![0].timing!.repeat!.when![0]
              )
            : undefined
          : undefined
        : undefined
      : undefined,
    timeOfDayCode: medicationStatement.dosage
      ? medicationStatement.dosage![0].timing
        ? medicationStatement.dosage![0].timing!.repeat!.when
          ? getTimeOfDayDisplay(
              medicationStatement.dosage![0].timing!.repeat!.when!
            )
          : undefined
        : undefined
      : undefined,
    startFrom: medicationStatement.dosage
      ? medicationStatement.dosage![0].timing
        ? getLocalDateTimeFromISOString(
            medicationStatement.dosage![0].timing!.repeat?.boundsPeriod?.start!
          )
        : undefined
      : undefined,
    till: medicationStatement.dosage
      ? medicationStatement.dosage![0].timing
        ? getLocalDateTimeFromISOString(
            medicationStatement.dosage![0].timing!.repeat?.boundsPeriod?.end!
          )
        : undefined
      : undefined,
    textForm: medicationStatement.dosage
      ? medicationStatement.dosage![0].text
      : '',
    brandName: medicationStatement.medicationCodeableConcept
      ? medicationStatement.medicationCodeableConcept?.text ?? ''
      : '',
    date:
      medicationStatement.effectiveDateTime ??
      medicationStatement.dateAsserted ??
      '',
    medicationDate: medicationStatement.effectiveDateTime
      ? moment(medicationStatement.effectiveDateTime).toDate()
      : new Date(),
    // medication: {
    //   resourceType: 'Medication',
    //   identifier: [medicationRequest.medicationReference!.identifier!],
    // },
    name: medicationStatement.medicationReference
      ? medicationStatement.medicationReference.display ?? ''
      : '',
    code: medicationStatement.medicationReference
      ? medicationStatement.medicationReference.identifier
        ? medicationStatement.medicationReference.identifier.value ?? ''
        : ''
      : '',
    notes: getNotesString(medicationStatement.note ?? []),
    status: medicationStatement.status
      ? medicationStatement.status === 'active'
      : false,
    cancellationReason: getExtensionCodingOfUrlForExam(
      medicationStatement.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-cancellation-reason-ext'
    ),
  }

  const oushadhaKalaExtension = getExtensionValueOfUrl(
    medicationStatement.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext'
  )
  if (
    oushadhaKalaExtension &&
    oushadhaKalaExtension.valueCodeableConcept &&
    oushadhaKalaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.aushadhaKalaCode =
      oushadhaKalaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaExtension = getExtensionValueOfUrl(
    medicationStatement.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext'
  )
  if (
    anupanaExtension &&
    anupanaExtension.valueCodeableConcept &&
    anupanaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.anupanaCode =
      anupanaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaDosageExtension = getExtensionValueOfUrl(
    medicationStatement.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext'
  )
  if (
    anupanaDosageExtension &&
    anupanaDosageExtension.valueDosage &&
    anupanaDosageExtension.valueDosage.doseAndRate &&
    anupanaDosageExtension.valueDosage.doseAndRate[0] &&
    anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity
  ) {
    welloMedication.anupanaDosageQuantity =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.value
    welloMedication.anupanaDosageQuantityType =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.unit
  }

  welloMedication.type = 'allopathy'

  const medicationTypeCode = getExtensionValueOfUrl(
    medicationStatement.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
  )

  console.log('-----------medicationTypeCode--------------', medicationTypeCode)

  if (medicationTypeCode && medicationTypeCode.valueCodeableConcept) {
    const code = getCodeOfSystemFromCodableConcept(
      medicationTypeCode.valueCodeableConcept,
      'http://snomed.info/sct'
    )

    console.log('-----------code--------------', code)

    if (code && code.code === '452091000124101') {
      welloMedication.type = 'ayurveda'
    }
  }

  console.log('-----------welloMedication--------------', welloMedication)

  return welloMedication
}

export function getWelloMedicationFromMedicationRequest(
  medicationRequest: R4.IMedicationRequest
): WelloMedication {
  const medData: R4.IMedication = {
    resourceType: 'Medication',
  }
  const welloMedication: WelloMedication = {
    id: medicationRequest.id ?? '',
    base: 'request',
    medicationRequest,
    medicationCode: medicationRequest.medicationCodeableConcept?.coding![0]!,
    rxNormCode: medicationRequest.medicationCodeableConcept?.coding![1]!,

    administrationCode: medicationRequest.dosageInstruction![0].route
      ? getRouteOfAdminCodeFromMedicationRequest(
          medicationRequest.dosageInstruction![0].route!
        )
      : undefined,
    dosageQuantity: medicationRequest.dosageInstruction![0].doseAndRate
      ? medicationRequest.dosageInstruction![0].doseAndRate![0].doseQuantity!
          .value!
      : undefined,
    dosageQuantityType: medicationRequest.dosageInstruction![0].doseAndRate
      ? getUnitOfDosageFromRequest(
          medicationRequest.dosageInstruction![0].doseAndRate![0].doseQuantity!
            .unit!
        )
      : undefined,
    date: medicationRequest.dosageInstruction![0].timing
      ? moment(
          getLocalDateTimeFromISOString(
            medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
              ?.start!
          )
        ).toISOString()
      : '',
    groupedDate: medicationRequest.dosageInstruction![0].timing
      ? moment(
          getLocalDateTimeFromISOString(
            medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
              ?.start!
          )
        ).format('YYYY-MM-DD')
      : '',
    mealCode: medicationRequest.dosageInstruction![0].timing
      ? getMealFromMedicationRequest(
          medicationRequest.dosageInstruction![0].timing!.repeat!.when![0]
        )
      : undefined,
    timeOfDayCode: medicationRequest.dosageInstruction![0].timing
      ? getTimeOfDay(
          medicationRequest.dosageInstruction![0].timing!.repeat!.when!
        )
      : undefined,
    startFrom: medicationRequest.dosageInstruction![0].timing
      ? getLocalDateTimeFromISOString(
          medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
            ?.start!
        )
      : undefined,
    till: medicationRequest.dosageInstruction![0].timing
      ? getLocalDateTimeFromISOString(
          medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
            ?.end!
        )
      : undefined,
    medication: medData,
    textForm: medicationRequest.dosageInstruction![0].text,
    medicationDate: new Date(),
    name: medicationRequest.medicationReference
      ? medicationRequest.medicationReference.display ?? ''
      : '',
    code: medicationRequest.medicationReference
      ? medicationRequest.medicationReference.identifier
        ? medicationRequest.medicationReference.identifier.value ?? ''
        : ''
      : '',

    timeCodes: medicationRequest.dosageInstruction![0].timing
      ? getTimeOfDayForPrescription(
          getTimeOfDay(
            medicationRequest.dosageInstruction![0].timing!.repeat!.when!
          )
        )
      : '',

    notes: hasNotesForChiefForNote(medicationRequest.note!)
      ? hasNotesForChiefForNote(medicationRequest.note!)!.text ?? ''
      : '',
  }
  const oushadhaKalaExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext'
  )
  if (
    oushadhaKalaExtension &&
    oushadhaKalaExtension.valueCodeableConcept &&
    oushadhaKalaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.aushadhaKalaCode =
      oushadhaKalaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext'
  )
  if (
    anupanaExtension &&
    anupanaExtension.valueCodeableConcept &&
    anupanaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.anupanaCode =
      anupanaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaDosageExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext'
  )
  if (
    anupanaDosageExtension &&
    anupanaDosageExtension.valueDosage &&
    anupanaDosageExtension.valueDosage.doseAndRate &&
    anupanaDosageExtension.valueDosage.doseAndRate[0] &&
    anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity
  ) {
    welloMedication.anupanaDosageQuantity =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.value
    welloMedication.anupanaDosageQuantityType =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.unit
  }

  welloMedication.type = 'allopathy'

  const medicationTypeCode = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
  )

  console.log('-----------medicationTypeCode--------------', medicationTypeCode)

  if (medicationTypeCode && medicationTypeCode.valueCodeableConcept) {
    const code = getCodeOfSystemFromCodableConcept(
      medicationTypeCode.valueCodeableConcept,
      'http://snomed.info/sct'
    )

    console.log('-----------code--------------', code)

    if (code && code.code === '452091000124101') {
      welloMedication.type = 'ayurveda'
    }
  }
  console.log('-----------welloMedication--------------', welloMedication)
  return welloMedication
}

export function getRouteOfAdminCodeFromMedicationRequest(
  codeableConcept: R4.ICodeableConcept
) {
  const code: string = codeableConcept.coding![0].code!

  switch (code.trim()) {
    case '26643006':
      return 'Oral (By Mouth)'
    case '47625008':
      return 'Intravenous (IV - Into Vein)'
    case '46713006':
      return 'Nasal (Through the Nose)'
    case '54485002':
      return 'Ophthalmic (In the Eye)'
    case '34206005':
      return 'Subcutaneous (SubQ - Under the Skin)'
    case '6064005':
      return 'Topical (External Application)'
    case '54471007':
      return 'Buccal (Between Cheek and Gum)'
    case '78421000':
      return 'Intramuscular (IM - Into Muscle)'
    case '10547007':
      return 'Otic (In the Ear)'
    case '37161004':
      return 'Rectal (By Rectum)'
    case '16857009':
      return 'Vaginal (Through the Vagina)'
    case '90028008':
      return 'Urethral (Through the Urethra)'
    default:
      return ''
  }
}

export function getRouteOfAdminCoding(code: string) {
  switch (code.trim()) {
    case '26643006':
    case 'Oral':
    case 'Oral (By Mouth)':
      return {
        system: 'http://snomed.info/sct',
        code: '26643006',
        display: 'Oral (By Mouth)',
      }
    case '47625008':
    case 'Intravenous':
    case 'Intravenous (IV - Into Vein)':
      return {
        system: 'http://snomed.info/sct',
        code: '47625008',
        display: 'Intravenous (IV - Into Vein)',
      }
    case '46713006':
    case 'Nasal':
    case 'Nasal (Through the Nose)':
      return {
        system: 'http://snomed.info/sct',
        code: '46713006',
        display: 'Nasal (Through the Nose)',
      }
    case '54485002':
    case 'Ophthalmic':
    case 'Ophthalmic (In the Eye)':
      return {
        system: 'http://snomed.info/sct',
        code: '54485002',
        display: 'Ophthalmic (In the Eye)',
      }
    case '34206005':
    case 'Subcutaneous':
    case 'Subcutaneous (SubQ - Under the Skin)':
      return {
        system: 'http://snomed.info/sct',
        code: '34206005',
        display: 'Subcutaneous (SubQ - Under the Skin)',
      }
    case '6064005':
    case 'Topical':
    case 'Topical (External Application)':
      return {
        system: 'http://snomed.info/sct',
        code: '6064005',
        display: 'Topical (External Application)',
      }
    case 'Urethral (Through the Urethra)':
    case '90028008':
      return {
        system: 'http://snomed.info/sct',
        code: '90028008',
        display: 'Urethral (Through the Urethra)',
      }
    case 'Vaginal (Through the Vagina':
    case '16857009':
      return {
        system: 'http://snomed.info/sct',
        code: '16857009',
        display: 'Vaginal (Through the Vagina',
      }
    case '54471007':
    case 'Buccal':
    case 'Buccal (Between Cheek and Gum)':
      return {
        system: 'http://snomed.info/sct',
        code: '54471007',
        display: 'Buccal (Between Cheek and Gum)',
      }
    case '78421000':
    case 'Intramuscular':
    case 'Intramuscular (IM - Into Muscle':
      return {
        system: 'http://snomed.info/sct',
        code: '78421000',
        display: 'Intramuscular (IM - Into Muscle',
      }
    case '10547007':
    case 'Otic':
    case 'Otic (In the Ear)':
      return {
        system: 'http://snomed.info/sct',
        code: '10547007',
        display: 'Otic (In the Ear)',
      }
    case '37161004':
    case 'Per Rectum':
    case 'Rectal (By Rectum)':
      return {
        system: 'http://snomed.info/sct',
        code: '37161004',
        display: 'Rectal (By Rectum)',
      }
    case '421134003':
    case 'Inhale':
      return {
        system: 'http://snomed.info/sct',
        code: '421134003',
        display: 'Inhale',
      }

    default:
      return undefined
  }
}

export function getUnitOfDosageFromRequest(code: string) {
  switch (code) {
    case 'Number':
    case '{Number}':
    case 'Number/s (Nos)':
    case 'Nos.':
    case 'Nos':
    case 'NUmber':
      return 'Number/s (Nos)'
    case '404218003':
    case 'Drop/s':
      return 'Drop/s'

    case 'ml':
    case 'mL':
    case 'Ml':
    case 'Milliliter/s (mL)':
    case 'Milliliter/s (ml)':
    case 'ML':
      return 'Milliliter/s (ml)'
    case 'g':
    case 'Gram/s (g)':
      return 'Gram/s (g)'
    case 'Package':
    case '{Package}':
    case 'Package/s':
      return 'Package/s'
    case 'tbs_us':
    case 'Tablespoon':
    case '[tbs_us]':
    case 'Tablespoon/s (tbsp)':
      return 'Tablespoon/s (tbsp)'

    case 'tsp_us':
    case '[tsp_us]':
    case 'Teaspoon':
    case 'Teaspoon/s (tsp)':
      return 'Teaspoon/s (tsp)'
    case 'mg':
    case 'MG':
      return 'MG'
    case 'pinch':
    case 'Pinch/es':
      return 'Pinch/es'
    case '258692008':
    case 'Ounce/s (oz)':
      return 'Ounce/s (oz)'
    case '[U]':
    case 'Unit/s (U)':
    case 'unit':
      return 'Unit/s (U)'
    case '426148002':
    case 'Sachet/s':
      return 'Sachet/s'
    case '422135004':
    case 'As Instructed/Directed':
      return 'As Instructed/Directed'
    default:
      return ''
  }
}

export function getUnitOfDosageCoding(code: string): R4.ICoding | undefined {
  switch (code) {
    case 'Nos.':
    case 'Number':
    case 'Number/s (Nos)':
    case '{Number}':
      return {
        system: 'http://unitsofmeasure.org',
        code: '{Number}',
        display: 'Number/s (Nos)',
      }
    case 'ml':
    case 'mL':
    case 'Ml':
    case 'Milliliter/s (mL)':
    case 'Milliliter/s (ml)':
    case 'ML':
      return {
        system: 'http://unitsofmeasure.org',
        code: 'ml',
        display: 'Milliliter/s (mL)',
      }

    case 'Package':
    case 'Package/s':
    case '{Package}':
      return {
        system: 'http://unitsofmeasure.org',
        code: '{Package}',
        display: 'Package/s',
      }
    case 'tbs_us':
    case '[tbs_us]':
    case 'Tablespoon':
    case 'Tablespoon/s (tbsp)':
      return {
        system: 'http://unitsofmeasure.org',
        code: '[tbs_us]',
        display: 'Tablespoon/s (tbsp)',
      }
    case 'tsp_us':
    case '[tsp_us]':
    case 'Teaspoon':
    case 'Teaspoon/s (tsp)':
      return {
        system: 'http://unitsofmeasure.org',
        code: '[tsp_us]',
        display: 'Teaspoon/s (tsp)',
      }
    case 'pinch':
    case 'Pinch/es':
      return {
        system: 'http://unitsofmeasure.org',
        code: 'pinch',
        display: 'Pinch/es',
      }
    case '404218003':
    case 'Drop/s':
      return {
        system: 'http://unitsofmeasure.org',
        code: '404218003',
        display: 'Drop/s',
      }
    case 'g':
    case 'Gram/s (g)':
      return {
        system: 'http://unitsofmeasure.org',
        code: 'g',
        display: 'Gram/s (g)',
      }
    case '422135004':
    case 'As Instructed/Directed':
      return {
        system: 'http://unitsofmeasure.org',
        code: '422135004',
        display: 'As Instructed/Directed',
      }
    case '426148002':
    case 'Sachet/s':
      return {
        system: 'http://unitsofmeasure.org',
        code: '426148002',
        display: 'Sachet/s',
      }
    case '258692008':
    case 'Ounce/s (oz)':
      return {
        system: 'http://unitsofmeasure.org',
        code: '258692008',
        display: 'Ounce/s (oz)',
      }
    case '[U]':
    case 'Unit/s (U)':
    case 'unit':
      return {
        system: 'http://unitsofmeasure.org',
        code: '[U]',
        display: 'unit',
      }

    default:
      return undefined
  }
}

export function getUnitOfRouteRequest(code: string) {
  switch (code) {
    case 'oral':
    case 'Oral':
    case 'Oral (By Mouth)':
    case '26643006':
      return 'Oral (By Mouth)'
    case 'nasal':
    case 'Nasal':
    case 'Nasal (Through the Nose)':
    case '46713006':
      return 'Nasal (Through the Nose)'
    case 'opthalmic':
    case 'Ophthalmic':
    case 'Ophthalmic (In the Eye)':
    case '54485002':
      return 'Ophthalmic (In the Eye)'
    case 'subcutaneous':
    case 'Subcutaneous (SubQ - Under the Skin)':
    case 'Subcutaneous':
    case '34206005':
      return 'Subcutaneous (SubQ - Under the Skin)'
    case 'topical':
    case 'Topical':
    case 'Topical (External Application)':
    case '6064005':
      return 'Topical (External Application)'
    case 'Buccal':
    case 'Buccal (Between Cheek and Gum)':
    case '54471007':
      return 'Buccal (Between Cheek and Gum)'
    case 'Intramuscular':
    case 'Intramuscular (IM - Into Muscle)':
    case '78421000':
      return 'Intramuscular (IM - Into Muscle)'
    case '47625008':
    case 'Intravenous':
    case 'Intravenous (IV - Into Vein)':
      return 'Intravenous (IV - Into Vein)'
    case 'Otic':
    case 'Otic (In the Ear)':
    case '10547007':
      return 'Otic (In the Ear)'
    case 'Per Rectum':
    case 'Rectal (By Rectum)':
    case '37161004':
      return 'Rectal (By Rectum)'
    case '90028008':
    case 'Urethral (Through the Urethra)':
      return 'Urethral (Through the Urethra)'
    case '16857009':
    case 'Vaginal (Through the Vagina)':
      return 'Vaginal (Through the Vagina)'
    case 'Inhale':
      return 'Inhale'
    default:
      return 'NA'
  }
}

export function getMealFromMedicationRequest(
  codeableConcept: R4.Timing_RepeatWhenKind
) {
  switch (codeableConcept) {
    case R4.Timing_RepeatWhenKind._acm:
    case R4.Timing_RepeatWhenKind._ac:
    case R4.Timing_RepeatWhenKind._acd:
    case R4.Timing_RepeatWhenKind._acv:
      return 'Before'
    case R4.Timing_RepeatWhenKind._pcd:
    case R4.Timing_RepeatWhenKind._pc:
    case R4.Timing_RepeatWhenKind._pcv:
    case R4.Timing_RepeatWhenKind._pcm:
      return 'After'
    case R4.Timing_RepeatWhenKind._cm:
    case R4.Timing_RepeatWhenKind._c:
    case R4.Timing_RepeatWhenKind._cd:
    case R4.Timing_RepeatWhenKind._cv:
      return 'Anytime'

    default:
      return 'NA'
  }
}

export function getTimeOfDay(
  codeableConcept: R4.Timing_RepeatWhenKind[]
): string[] {
  const codes: string[] = []

  codeableConcept.forEach((e) => {
    switch (e) {
      case R4.Timing_RepeatWhenKind._acm:
      case R4.Timing_RepeatWhenKind._pcm:
      case R4.Timing_RepeatWhenKind._cm:
        codes.push('morning')
        break
      case R4.Timing_RepeatWhenKind._ac:
      case R4.Timing_RepeatWhenKind._pc:
      case R4.Timing_RepeatWhenKind._c:
        codes.push('evening')
        break
      case R4.Timing_RepeatWhenKind._acd:
      case R4.Timing_RepeatWhenKind._pcd:
      case R4.Timing_RepeatWhenKind._cd:
        codes.push('afternoon')
        break
      case R4.Timing_RepeatWhenKind._acv:
      case R4.Timing_RepeatWhenKind._pcv:
      case R4.Timing_RepeatWhenKind._cv:
        codes.push('night')
        break

      default:
    }
  })
  return codes
}

export function getTimeOfDayForPrescription(codes: string[]): string {
  const finalCodes: string[] = []
  const finalResult = codes.filter((d: string) => d === 'morning')
  if (finalResult.length > 0) {
    finalCodes.push('1')
  } else {
    finalCodes.push('0')
  }
  const finalResult1 = codes.filter((d: string) => d === 'afternoon')
  if (finalResult1.length > 0) {
    finalCodes.push('1')
  } else {
    finalCodes.push('0')
  }
  //   const finalResult2 = codes.filter((d: string) => d === 'evening')
  //   if (finalResult2.length > 0) {
  //     finalCodes.push('1')
  //   } else {
  //     finalCodes.push('0')
  //   }
  const finalResult3 = codes.filter((d: string) => d === 'night')
  if (finalResult3.length > 0) {
    finalCodes.push('1')
  } else {
    finalCodes.push('0')
  }

  return finalCodes.join(' - ')
}

export function getTimeOfDayDisplay(
  codeableConcept: R4.Timing_RepeatWhenKind[]
): string[] {
  const codes: string[] = []

  codeableConcept.forEach((e) => {
    switch (e) {
      case R4.Timing_RepeatWhenKind._acm:
      case R4.Timing_RepeatWhenKind._pcm:
      case R4.Timing_RepeatWhenKind._cm:
        codes.push('Morning')
        break
      case R4.Timing_RepeatWhenKind._ac:
      case R4.Timing_RepeatWhenKind._pc:
      case R4.Timing_RepeatWhenKind._c:
        codes.push('Evening')
        break
      case R4.Timing_RepeatWhenKind._acd:
      case R4.Timing_RepeatWhenKind._pcd:
      case R4.Timing_RepeatWhenKind._cd:
        codes.push('Afternoon')
        break
      case R4.Timing_RepeatWhenKind._acv:
      case R4.Timing_RepeatWhenKind._pcv:
      case R4.Timing_RepeatWhenKind._cv:
        codes.push('Night')
        break

      default:
    }
  })

  return codes
}

export function getTimeOfDayDisplayForStatement(
  codeableConcept: string[]
): string[] {
  const codes: string[] = []

  codeableConcept.forEach((e) => {
    switch (e) {
      case 'morning':
      case 'Morning':
        codes.push('Morning')
        break
      case 'evening':
      case 'Evening':
        codes.push('Evening')
        break
      case 'afternoon':
      case 'Afternoon':
        codes.push('Afternoon')
        break
      case 'night':
      case 'Night':
        codes.push('Night')
        break

      default:
    }
  })
  return codes
}

export function getTimeOfDayDisplayMeal(codeableConcept: string): string {
  let codes: string = ''

  switch (codeableConcept) {
    case 'after':
    case 'After':
      codes = 'After'
      break
    case 'before':
    case 'Before':
      codes = 'Before'
      break
    case 'anytime':
    case 'Anytime':
      codes = 'Anytime'
      break

    default:
  }

  return codes
}

export function getTimeOfDayDisplayMealForPrescription(
  codeableConcept: string
): string {
  let codes: string = ''

  switch (codeableConcept) {
    case 'after':
    case 'After':
      codes = 'After Meal'
      break
    case 'before':
    case 'Before':
      codes = 'Before Meal'
      break
    case 'anytime':
    case 'Anytime':
      codes = 'Meal'
      break

    default:
  }

  return codes
}

export function getMealStatus(codeableConcept: R4.ICodeableConcept) {
  const code: string = codeableConcept.coding![0].code!

  switch (code) {
    case '26643006':
      return 'Oral (By Mouth)'

    case '47625008':
      return 'Intravenous'
    case '46713006':
      return 'Nasal'
    default:
      return 'NA'
  }
}

export function getMedicationCodeSystemForSearch(name: string) {
  switch (name) {
    case 'Calcium Channel Blockers (CCB)':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0006684'

    case 'ACE Inhibitor or ARB Ingredient':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0003015'

    case 'Glimepiride':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0061323'

    case 'Gliptin':
      return 'http://terminology.hl7.org/CodeSystem/umls|C1958126'

    case 'Angiotensin receptor blockers':
      return 'http://terminology.hl7.org/CodeSystem/umls|C1449680'

    case 'Metformin':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0025598'

    case 'Loop Diuretics':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0354100'

    case 'Thiazide and Thiazide Like Diuretics':
      return 'http://terminology.hl7.org/CodeSystem/umls|372747003'

    case 'Statin':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0286651'

    case 'Antiplatelet':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0085826'

    case 'Beta Blockers':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0001645'

    case '20 ML magnesium sulfate 500 MG/ML Injection':
      return 'http://terminology.hl7.org/CodeSystem/umls|C3887791'

    case 'Nicardipine Hydrochloride':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0770150'

    case '0 ML nitroglycerin 5 MG/ML Injection':
      return 'http://terminology.hl7.org/CodeSystem/umls|C4281864'

    case '2 ML sodium nitroprusside 25 MG/ML Injection':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0778643'

    case 'Esmolol hydrochloride 250 mg/mL solution for injection':
      return 'http://terminology.hl7.org/CodeSystem/umls|C0774585'

    case ' Labetalol hydrochloride 5 mg/mL solution for injection':
      return 'http://snomed.info/sct|C0025598'

    default:
      return name
  }
}

export function getPriorityCodeDisplayFromCode(code: string) {
  switch (code) {
    case 'routine':
      return 'Normal'

    case 'urgent':
      return 'Urgent'

    case 'asap':
      return 'ASAP'

    case 'stat':
      return 'Immediate'

    default:
      return undefined
  }
}

export function getMedicationNames(
  medicationList: WelloMedication[]
): string[] {
  const medicationNames: string[] = []
  for (let i = 0; i < medicationList.length; i++) {
    if (medicationList[i].medicationCode) {
      const medicationData = medicationList[i].medicationCode
      if (medicationData) {
        medicationNames.push(medicationData.display ?? '')
      }
    }
  }
  if (medicationNames.length === 0) {
    for (let i = 0; i < medicationList.length; i++) {
      if (medicationList[i].name) {
        const medicationData = medicationList[i].name ?? ''
        if (medicationData && medicationData.length > 0) {
          medicationNames.push(medicationData)
        }
      }
    }
  }

  return medicationNames
}

export function getPaymentMessage(code: string) {
  switch (code) {
    case 'confirmed':
      return 'Payment Successful'

    case 'draft':
      return 'Payment Pending'

    default:
      return ''
  }
}

export function getMRP(salesorders: any) {
  return salesorders.total + salesorders.discount
}

// export function getTotal(salesorders: any) {
//     return salesorders.total + salesorders.discount
//   }

export async function getAaddressByContactId(
  contactId: string
): Promise<string> {
  let addressData: string | undefined = ''

  const contact = await getContactDetailsObjById(contactId)

  if (contact) {
    if (contact.notes === getCurrentUserPractitionerDetails().id!) {
      addressData = getAddress(getCurrentUserUnitDetails().address).trim() ?? ''
    } else {
      addressData = await getAddressesForDisplay(contact.notes)
    }
  }

  return addressData ?? ''
}

export async function getPatientDetailsByContactId(
  contactId: string
): Promise<R4.IPatient | undefined> {
  const contact = await getContactDetailsObjById(contactId)

  if (contact) {
    if (contact.notes === getCurrentUserPractitionerDetails().id!) {
      return undefined
    }
    const patientData: R4.IPatient | undefined = await getPatientForDisplay(
      contact.notes
    )
    return patientData
  }

  return undefined
}

export const getContactDetailsObjById = async (id: string) => {
  try {
    const fhirClient: InventoryClient = new InventoryClient()

    const response: any = await fhirClient.doGetResource(
      `/inventory/v1/contacts/${id}`
    )

    if (response) {
      return response.contact
    }
    return undefined
  } catch (error) {
    console.log(error)
    return undefined
  }
}

export function getUnitData(code: string) {
  switch (code) {
    case 'pcs':
      return ''

    default:
      return code
  }
}

export function getWelloMedicationFromMedicationRequestForHistory(
  medicationRequest: R4.IMedicationRequest,
  encounterId?: String
): WelloMedication {
  const medData: R4.IMedication = {
    resourceType: 'Medication',
  }
  const encounter = medicationRequest

  const welloMedication: WelloMedication = {
    id: medicationRequest.id ?? '',
    base: 'request',
    medicationRequest,
    medicationCode: medicationRequest.medicationCodeableConcept?.coding![0]!,
    rxNormCode: medicationRequest.medicationCodeableConcept?.coding![1]!,

    administrationCode: medicationRequest.dosageInstruction![0].route
      ? getRouteOfAdminCodeFromMedicationRequest(
          medicationRequest.dosageInstruction![0].route!
        )
      : undefined,
    dosageQuantity: medicationRequest.dosageInstruction![0].doseAndRate
      ? medicationRequest.dosageInstruction![0].doseAndRate![0].doseQuantity!
          .value!
      : undefined,
    dosageQuantityType: medicationRequest.dosageInstruction![0].doseAndRate
      ? getUnitOfDosageFromRequest(
          medicationRequest.dosageInstruction![0].doseAndRate![0].doseQuantity!
            .unit!
        )
      : undefined,
    date: medicationRequest.dosageInstruction![0].timing
      ? moment(
          getLocalDateTimeFromISOString(
            medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
              ?.start!
          )
        ).toISOString()
      : '',
    groupedDate: medicationRequest.dosageInstruction![0].timing
      ? moment(
          getLocalDateTimeFromISOString(
            medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
              ?.start!
          )
        ).format('YYYY-MM-DD')
      : '',
    mealCode: medicationRequest.dosageInstruction![0].timing
      ? getMealFromMedicationRequest(
          medicationRequest.dosageInstruction![0].timing!.repeat!.when![0]
        )
      : undefined,
    timeOfDayCode: medicationRequest.dosageInstruction![0].timing
      ? getTimeOfDay(
          medicationRequest.dosageInstruction![0].timing!.repeat!.when!
        )
      : undefined,
    startFrom: medicationRequest.dosageInstruction![0].timing
      ? getLocalDateTimeFromISOString(
          medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
            ?.start!
        )
      : undefined,
    till: medicationRequest.dosageInstruction![0].timing
      ? getLocalDateTimeFromISOString(
          medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
            ?.end!
        )
      : undefined,
    medication: medData,
    textForm: medicationRequest.dosageInstruction![0].text,
    medicationDate: new Date(),
    name: medicationRequest.medicationReference
      ? medicationRequest.medicationReference.display ?? ''
      : '',
    code: medicationRequest.medicationReference
      ? medicationRequest.medicationReference.identifier
        ? medicationRequest.medicationReference.identifier.value ?? ''
        : ''
      : '',

    timeCodes: medicationRequest.dosageInstruction![0].timing
      ? getTimeOfDayForPrescription(
          getTimeOfDay(
            medicationRequest.dosageInstruction![0].timing!.repeat!.when!
          )
        )
      : '',

    notes: hasNotesForChiefForNote(medicationRequest.note!)
      ? hasNotesForChiefForNote(medicationRequest.note!)!.text ?? ''
      : '',
    status: medicationRequest.status
      ? medicationRequest.status === 'active'
      : false,
    cancellationReason: getExtensionCodingOfUrlForExam(
      medicationRequest.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-cancellation-reason-ext'
    ),
  }
  const oushadhaKalaExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext'
  )
  if (
    oushadhaKalaExtension &&
    oushadhaKalaExtension.valueCodeableConcept &&
    oushadhaKalaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.aushadhaKalaCode =
      oushadhaKalaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext'
  )
  if (
    anupanaExtension &&
    anupanaExtension.valueCodeableConcept &&
    anupanaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.anupanaCode =
      anupanaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaDosageExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext'
  )
  if (
    anupanaDosageExtension &&
    anupanaDosageExtension.valueDosage &&
    anupanaDosageExtension.valueDosage.doseAndRate &&
    anupanaDosageExtension.valueDosage.doseAndRate[0] &&
    anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity
  ) {
    welloMedication.anupanaDosageQuantity =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.value
    welloMedication.anupanaDosageQuantityType =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.unit
  }

  welloMedication.type = 'allopathy'

  const medicationTypeCode = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
  )

  console.log('-----------medicationTypeCode--------------', medicationTypeCode)

  if (medicationTypeCode && medicationTypeCode.valueCodeableConcept) {
    const code = getCodeOfSystemFromCodableConcept(
      medicationTypeCode.valueCodeableConcept,
      'http://snomed.info/sct'
    )

    console.log('-----------code--------------', code)

    if (code && code.code === '452091000124101') {
      welloMedication.type = 'ayurveda'
    }
  }
  console.log('-----------welloMedication--------------', welloMedication)
  return welloMedication
}

export function getStartTime(date: Date) {
  const diffStart = moment(date).endOf('day').diff(moment().endOf('day'), 'day')

  if (diffStart <= 0) {
    return 'today'
  }
  if (diffStart === 1) {
    return 'tomo'
  }
  return 'custom'
}

export function getEndTime(startDate: Date, endDate: Date) {
  const diffEnd = moment(endDate)
    .endOf('day')
    .diff(moment(startDate).endOf('day'), 'day')

  if (diffEnd <= 3) {
    return '3d'
  }
  if (diffEnd === 7) {
    return '1W'
  }
  if (diffEnd === 14) {
    return '2W'
  }
  if (diffEnd === 21) {
    return '3W'
  }
  return 'custom'
}

export function displayReason(
  reqId: string,
  medRequest: R4.IMedicationRequest
): boolean {
  if (reqId.length > 0) {
    if (medRequest.id) {
      if (reqId === medRequest.id) {
        return true
      }
    }
  }
  return false
}

export function displayReasonForStatement(
  reqId: string,
  medRequest: R4.IMedicationStatement
): boolean {
  if (reqId.length > 0) {
    if (medRequest.id) {
      if (reqId === medRequest.id) {
        return true
      }
    }
  }
  return false
}

export function getCategoryCode(row: WelloMedication): boolean {
  let data: boolean = false
  if (row.medicationRequest) {
    const code =
      getDefaultCodeOfSystemFromCodableConceptList(
        row.medicationRequest.category ?? []
      ) ?? ''
    if (code !== 'patientspecified') {
      data = true
    }
  } else if (row.medicationStatement) {
    const code = getDefaultCode(row.medicationStatement.category) ?? ''
    if (code !== 'patientspecified') {
      data = true
    }
  }
  return data
}

export function getTimeOfTheCode(row: string[]): string[] {
  const data: string[] = []
  if (row.length > 0) {
    for (let i = 0; i < row.length; i++) {
      data.push(row[i].toLowerCase())
    }
  }
  return data
}
